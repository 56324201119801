import React from "react";
import HardWay from './hardWay/HardWay';
import SellerHome from "./SellerHome";
import SellerEasyWay from "./SellerEasyWay";
import SellerFeatures from "./SellerFeatures";
import SellerCloseDeals from "./SellerCloseDeals";
import SellerCallForAction from "./SellerCallForAction";
import SellerSteps from "./SellerSteps";
import SellerTagline from "./SellerTagline";

export default function Seller() {
    return (
        <>
            <SellerHome />
            <HardWay />
            <SellerEasyWay/>
            <SellerCloseDeals />
            <SellerCallForAction />
            <SellerFeatures />
            <SellerSteps />
            <SellerTagline />
        </>
    )
}