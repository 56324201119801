import React from "react";
import BuyerHome from "./BuyerHome";
import BuyerEasyWay from "./BuyerEsayWay";
import BuyerFeatures from "./BuyerFeatures";
import BuyerPromise from "./BuyerPromise";


export default function Buyer() {
    return (
        <>
            <BuyerHome />
            <BuyerEasyWay />
            <BuyerFeatures />
            <BuyerPromise />
        </>
    )
}