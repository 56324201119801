import React, { useEffect, useState } from 'react';

export default function Header({ifBuyer, setIfBuyer}) {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function handleClick() {
        setIfBuyer(!ifBuyer);
        
        window.scrollTo({
            top: 0,
          });
    }

    return (
        <header className={`fixed top-0 w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-white drop-shadow-md py-6 px-12' : 'bg-gradient-to-r from-purple-700 to-purple-500 py-6 px-12 text-white'}`}>
            <div className="flex items-center justify-between max-w-container mx-auto">
                <img 
                    src={`${isScrolled ? 'https://i.postimg.cc/HxxXsjxx/logo-no-text-black.png' : 'https://i.postimg.cc/g296TqS2/logo.png'}`} 
                    alt='logo' 
                    className='h-9'
                />
                <div className="flex items-center gap-8">
                    <ul className="flex items-center text-sm gap-8">
                        {/* <li className='cursor-pointer hover:opacity-60 duration-100 font-medium'>Item One</li>
                        <li className='cursor-pointer hover:opacity-60 duration-100 font-medium'>Item Two</li>
                        <li className='cursor-pointer hover:opacity-60 duration-100 font-medium'>Item Three</li> */}
                        <li className={`cursor-pointer hover:opacity-60 duration-100 font-medium ${isScrolled ? 'text-black' : 'text-white'}`}>
                            <a href="https://app.amivaplus.com/" target="_blank" rel="noreferrer">Sign In</a>
                        </li>
                    </ul>
                    <a href="https://app.amivaplus.com/register?trial=new&token=PuMG3da3LG0lD93100lkPQP" target="_blank" rel="noreferrer">
                        <p className={`text-sm px-8 py-2 border rounded-full cursor-pointer transition-all duration-300 ${isScrolled ? 'border-black text-black hover:bg-black hover:text-white' : 'border-white text-white hover:bg-white hover:text-black'}`}>
                            Premium Trial
                        </p>
                    </a>
                    <p onClick={handleClick} className='text-sm px-6 py-2.5 bg-black rounded-full text-white hover:opacity-70 cursor-pointer'>Are you a <span className='underline'>{ifBuyer ? 'broker' : 'buyer'}</span>?</p>
                </div>
            </div>
        </header>
    );
}
