import React, { useState, useEffect } from "react";
import PrivacyPolicy from "./PrivacyPolicy";

export default function Footer() {
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

    const terms_link = 'https://storage.googleapis.com/amiva_public_files/AMIVA%2B%2C%20Inc.%20-%20Terms%20of%20Use%20for%20www.app.amivaplus.com%20-%20Version%202.0.pdf'

    // if route ends in /privacy-policy, show the privacy policy
    useEffect(() => {
        if (window.location.pathname === '/privacy-policy') {
            setShowPrivacyPolicy(true);
        }
    }, [])

    return (
        <div className="px-12 pt-24 pb-7 text-center">
            <p className="text-sm">Amiva Plus © 2024</p>

            <div className="flex items-center justify-center mt-4 ml-2">
                <p className="text-sm opacity-60">
                    <a className="text-black hover:underline cursor-pointer" rel="noreferrer" href={'https://www.amivaplus.com/privacy-policy'}> 
                        Privacy Policy </a>
                     and <a className="text-black hover:underline cursor-pointer" rel="noreferrer" href={terms_link} target='_blank'> 
                        Terms of Use
                    </a>
                </p>
            </div>

            {showPrivacyPolicy && <PrivacyPolicy setShow={setShowPrivacyPolicy} />}
        </div>
    )
}