import React from "react";

export default function SellerCallForAction() {
    return (
        <div className="max-w-container mx-auto p-12">
            <div 
                className="bg-purple text-white px-16 py-24 rounded-lg text-center shadow-lg transition-transform transform"
                style={{color: 'white' }}
                data-aos="fade-up"
            >
                <h1 className="text-4xl font-semibold max-w-[950px] mx-auto"  >
                    AMIVA+ was built by dealmakers, for dealmakers
                </h1>

                <p className="mt-8 max-w-[800px] mx-auto text opacity-80"  >
                    No complicated technology—no need for multiple platforms—just easy-to-use software that makes deal management simple and effective. We also made it free for you to try it yourself.
                </p>

                <a href="https://app.amivaplus.com/register?trial=new&token=PuMG3da3LG0lD93100lkPQP" target="_blank" rel="noreferrer"  className="inline-block mt-8" aria-label="Free Trial Registration">
                    <div className="inline-flex items-center gap-2 px-8 py-3 bg-black text-white rounded-full cursor-pointer transition hover:drop-shadow-lg transform group">
                        <p className="font-semibold">Free Trial</p>
                        <i className='bx bx-chevron-right group-hover:translate-x-[3px] duration-100'></i>
                    </div>
                </a>
            </div>
        </div>
    );
}
