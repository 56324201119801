import React from "react";
import GifOne from '../../images/Comp_1.gif';
import GifTwo from '../../images/Comp_2.gif';
import GifThree from '../../images/Comp_3.gif';

export default function SellerEasyWay() {
    return (
        <div className="bg-[#fafafa] w-full py-24">
            <div className="max-w-container mx-auto px-12 overflow-hidden">
                <h1 className="text-4xl font-semibold text-center text-purple-900" data-aos="fade-up">
                    <span className="underline decoration-blue-400 decoration-4">The easy way</span> to close sell-side mandates
                </h1>

                <div className="grid grid-cols-1 lg:grid-cols-2 mt-24 gap-24">
                    <div className="w-full rounded-md overflow-hidden" data-aos="fade-right">
                        <img src={GifOne} alt="gif" className="w-full h-full object-cover" />
                    </div>

                    <div className="self-center" data-aos="fade-left">
                        <p className="text-2xl font-semibold mb-2 text-purple-900">1. Prepare your listing</p>
                        <p className="font-semibold opacity-70 mb-16 text-lg">Enter basic details about your mandate</p>

                        <ul>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Describe the business
                            </li>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Add CIM & teaser
                            </li>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Attach your firm's NDA
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 mt-24 gap-24">
                    <div className="justify-self-end self-center order-2 lg:order-1" data-aos="fade-right">
                        <p className="text-2xl font-semibold mb-2 text-purple-900">2. Manage your deal</p>
                        <p className="font-semibold opacity-70 mb-16 text-lg max-w-lg">Utilize our automated advanced back office support</p>

                        <ul>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Auto collect NDAs from vetted buyers
                            </li>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Store and share data room with one click
                            </li>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Natively invite due diligence parties to collaborate
                            </li>
                        </ul>
                    </div>

                    <div className="w-full rounded-md overflow-hidden order-1 lg:order-2" data-aos="fade-left">
                        <img src={GifTwo} alt="gif" className="w-full h-full object-cover" />
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 mt-24 gap-24">
                    <div className="w-full rounded-md overflow-hidden" data-aos="fade-right">
                        <img src={GifThree} alt="gif" className="w-full h-full object-cover" />
                    </div>

                    <div className="self-center" data-aos="fade-left">
                        <p className="text-2xl font-semibold mb-2 text-purple-900">3. Close More Deals, Work Less</p>
                        <p className="font-semibold opacity-70 mb-16 text-lg max-w-lg">We handle the busy work so you can focus on closing more & better deals</p>

                        <ul>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Automated CRM entry and management
                            </li>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Multi-channel marketing deal support
                            </li>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Centralize deal communications
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
