import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css';
import './index.css';
import Header from './components/general/Header';
import Footer from './components/general/Footer';
import ReactGA from "react-ga4";
import Seller from './components/Seller/Seller';
import Buyer from './components/Buyer/Buyer';

function App() {
  const [ifBuyer, setIfBuyer] = useState(false);

  ReactGA.initialize("G-RGF4PXBZX8");

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="App">
      <Header ifBuyer={ifBuyer} setIfBuyer={setIfBuyer}/>
        {ifBuyer ? 
          <Buyer />
          :
          <Seller />
        }
      <Footer />
    </div>
  );
}

export default App;
