import React from "react";
import Typewriter from "../general/TypeWriter";

export default function BuyerHome() {
    return (
        <div className="relative h-screen bg-purple p-12 w-full background-image-container">
            <div className="flex items-center max-w-container ml-auto mr-auto text-white h-full">
                <div>
                    <div className="h-[96px]">
                        <Typewriter text={'Find vetted deals in the 1-25M\nEBITDA range. No hassle, no fees'} speed={20}/>

                    </div>
                    <p className="mt-8">Access a wealth of opportunities, with personalized alerts when deals match your criteria</p>

                    <a href="https://app.amivaplus.com/register?token=PuMG3da3LG0lD93100lkPQP" target="_blank" rel="noreferrer">
                        <div className="inline-flex items-center gap-2 px-8 py-3 bg-black mt-4 rounded-full cursor-pointer group">
                            <p className="font-semibold">Join Now</p>
                            <i className='bx bx-chevron-right
                            group-hover:translate-x-[3px] duration-100'></i>
                        </div>
                    </a>
                </div>
            </div>

            {/* add arrow pointing down until scroll begins. On click, scroll to targetDiv */}
            <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 cursor-pointer" onClick={() => {
                const targetDiv = document.getElementById('targetDiv');
                targetDiv.scrollIntoView({ behavior: 'smooth' });
            }}>
                <i className='bx bx-chevron-down text-white text-3xl animate-bounce'></i>
            </div>

        </div>
    )
}